.main-portal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100vw;
  height: auto;
  min-height: calc(100vh - 105px);
  background-color: transparent;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute;
  top: 105px;
  scroll-behavior: smooth;
}

@media screen and (width < 1200px) {
  #main-portal-container {
    max-width: 1250px;
  }
}
